export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {
      cards: [],
      intervalID: null,
      randomNumber: 900,
    }
  },

  props: {
    loading: Boolean,
    labels: Object,
  },

  mounted() {
    this.addCard()
    /*this.$nextTick(() => {
      this.intervalID = setInterval(this.addCard, 1800)
    })*/
  },

  beforeDestroy() {
    //clearInterval(this.intervalID)
  },

  watch: {
    loading: function (oldValue) {
      if (!oldValue) {
        this.addCard()
      }
    },
  },

  methods: {
    addCard() {
      const min = 900
      const max = 1999
      const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min
      const randomNumberTwo = Math.floor(Math.random() * (max - min + 1)) + min
      const randomNumberThree = Math.floor(Math.random() * (max - min + 1)) + min
      //this.cards.push({ visible: true, id: this.cards.length + 1, value: randomNumber.toFixed(2).replace('.', ',') })
      this.cards = [
        { visible: true, id: 1, value: randomNumber.toFixed(2).replace('.', ',') },
        { visible: true, id: 2, value: randomNumberTwo.toFixed(2).replace('.', ',') },
        { visible: true, id: 3, value: randomNumberThree.toFixed(2).replace('.', ',') },
      ]
    },
  },
}
