var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"organic-hero-carousel"},[_c('div',{staticClass:"organic-hero-carousel__images"},[_c('hot-image',_vm._b({class:[
        'organic-hero-carousel__image organic-hero-carousel__image--front',
        { 'organic-hero-carousel__image--loading': _vm.loading },
      ]},'hot-image',_vm.list[_vm.index].imageFront,false)),_vm._v(" "),_c('hot-image',_vm._b({class:[
        'organic-hero-carousel__image organic-hero-carousel__image--back',
        { 'organic-hero-carousel__image--loading': _vm.loading },
      ]},'hot-image',_vm.list[_vm.index].imageBack,false))],1),_vm._v(" "),_c('div',{class:['organic-hero-carousel__creator', { 'organic-hero-carousel__creator--loading': _vm.loading }]},[_c('hot-title',{attrs:{"tag":"h3"}},[_c('span',{staticClass:"name",domProps:{"innerHTML":_vm._s(_vm.list[_vm.index].creator.name)}})]),_vm._v(" "),_c('hot-title',{attrs:{"tag":"p"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.list[_vm.index].creator.description)}})]),_vm._v(" "),_c('hot-title',{attrs:{"tag":"p"}},[_c('span',{staticClass:"category",domProps:{"innerHTML":_vm._s(_vm.list[_vm.index].creator.category)}})])],1),_vm._v(" "),_c('organic-invoices',{attrs:{"loading":_vm.loading,"labels":_vm.labels}}),_vm._v(" "),_c('organic-loading',{attrs:{"progress":_vm.progress,"action":this.changeRunningSlide,"paused":_vm.runningSlide}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }