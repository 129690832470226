export default {
  components: {
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  data() {
    return {}
  },

  props: {
    progress: { type: Number, default: 0 },
    action: { type: Function },
    paused: { type: Boolean, default: false },
  },

  computed: {
    progressBar() {
      return `conic-gradient(#EB4B21 ${this.progress}%, white 0)`
    },

    isPaused() {
      // console.log(this.paused)
      return this.paused ? 'OrganicPlay' : 'OrganicPause'
    },
  },
}
