import organicInvoices from '~/components/Molecules/Organic/Invoices/Invoices.vue'
import organicLoading from '~/components/Molecules/Organic/Loading/Loading.vue'

export default {
  props: {
    list: Array,
    labels: Object,
  },

  components: {
    organicInvoices,
    organicLoading,
  },

  data() {
    return {
      index: 0,
      loading: true,
      intervalID: null,
      progress: 0,
      maxTicks: 500,
      maxTicksInvoices: 500,
      countTicks: 0,
      runningSlide: true,
    }
  },

  beforeDestroy() {
    cancelAnimationFrame(this.intervalID)
  },

  mounted() {
    this.animate()
  },

  computed: {
    lastImage() {
      return Boolean(this.index == this.list.length - 1)
    },
  },

  methods: {
    animate() {
      const animateLoop = () => {
        this.changeImages()
        this.intervalID = requestAnimationFrame(animateLoop)
      }
      animateLoop()
    },

    addProgress() {
      if (this.loading) {
        this.progress = this.progress + this.maxTicks / this.maxTicksInvoices
      }
    },

    changeRunningSlide() {
      this.runningSlide = !this.runningSlide
    },

    async changeImages() {
      if (!this.runningSlide) return

      if (this.countTicks === this.maxTicks) {
        this.countTicks = 0
        this.progress = 0
        this.loading = false

        await new Promise(resolve => {
          setTimeout(() => {
            resolve()
          }, 1000)
        })

        this.index = this.lastImage ? 0 : (this.index + 1) % this.list.length
        this.loading = true
      } else {
        this.countTicks++
        this.progress = this.progress + 100 / this.maxTicks
      }
    },
  },
}
